import React from "react";
import * as styles from "./HomeHero.module.css";
import { ImageItem } from "@kontent-ai/gatsby-components/dist/image-element";
import { KontentImageElement } from "../../elements/KontentImageElement/KontentImageElement";
import { Cta } from "../../elements/Cta/Cta";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { ComponentProps } from "../../../types/index";
import { SectionWrapper } from "../../containers/SectionWrapper";

export interface HeroProps extends ComponentProps {
  desktopImage: {
    item: ImageItem;
    alt: string;
  };
  mobileImage: {
    item: ImageItem;
    alt: string;
  };
  headline: string;
  subhead: string;
  introCopy?: string;
  ctaText?: string;
  ctaHref?: string;
  ctaAction?: "_blank" | "_self";
}

export const Hero: React.FC<HeroProps> = ({
  desktopImage,
  mobileImage,
  headline,
  subhead,
  introCopy,
  ctaText,
  ctaHref,
  ctaAction,
  id,
  anchor,
  hideOnMobile,
}) => {
  const breakpoints = useBreakpoint();
  let heroImage = desktopImage;

  if (mobileImage?.item?.url && !breakpoints.sm) {
    heroImage = mobileImage;
  }

  return (
    <SectionWrapper id={id} anchor={anchor} hideOnMobile={hideOnMobile}>
      <div className={styles.overlay}></div>
      <div className={styles.hero}>
        {heroImage?.item?.url && (
          <KontentImageElement
            className={styles.heroImage}
            image={heroImage?.item}
            alt={heroImage?.alt}
          />
        )}
        <div className={styles.heroContentWrap}>
          <div className={styles.heroContent}>
            {subhead && (
              <h2 data-kontent-element-codename="subhead">{subhead}</h2>
            )}
            {headline && (
              <h1 data-kontent-element-codename="headline">{headline}</h1>
            )}
            {introCopy && (
              <p data-kontent-element-codename="intro_copy">{introCopy}</p>
            )}
            {ctaText && ctaHref && (
              <Cta
                codename="link_configuration__link"
                href={ctaHref}
                label={ctaText}
                target={ctaAction}
              />
            )}
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};
