import React from "react";
import { graphql } from "gatsby";
import { Hero } from "./HomeHero";
import {
  StringValue,
  KontentComponentSettings,
  KontentMediaImage,
  KontentLinks,
} from "../../../types/index";
import { getMultiChoice } from "../../../lib/multichoice";
import { formatImages } from "../../../lib/images";
import { formatLinks } from "../../../lib/links";

export interface HomeHeroContainerProps extends KontentComponentSettings {
  headline: StringValue;
  intro_copy: StringValue;
  subhead: StringValue;
  background_image: { value: KontentMediaImage[] };
  pageId: string;
  link_configuration__link: KontentLinks;
}

const HomeHeroContainer: React.FC<HomeHeroContainerProps> = (props) => {
  const images = formatImages(props.background_image?.value[0]?.elements);
  const cta = formatLinks(props.link_configuration__link.modular_content);

  return (
    <Hero
      id={props.id}
      anchor={props.component_settings__anchor_name?.value}
      hideOnMobile={
        getMultiChoice(props.component_settings__hide_on_mobile) === "yes"
      }
      headline={props.headline?.value}
      subhead={props.subhead?.value}
      introCopy={props.intro_copy?.value}
      desktopImage={images.largeImage}
      mobileImage={images.smallImage}
      ctaText={cta[0]?.label}
      ctaHref={cta[0]?.href}
      ctaAction={cta[0]?.target as "_blank" | "_self"}
    />
  );
};

export default HomeHeroContainer;

export const fragmentHeroContainer = graphql`
  fragment HomeHeroContainer on kontent_item_component___home_hero {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      background_image {
        value {
          ...kontentMediaImage
        }
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      link_configuration__link {
        modular_content {
          ...kontentRichTextLink
        }
      }
      headline {
        value
      }
      intro_copy {
        value
      }
      subhead {
        value
      }
    }
  }
`;
